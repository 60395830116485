<template>
  <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <overlay v-if="!paymentDetail"></overlay>
    <payment :couponCode="couponCode" :paymentDetail="paymentDetail" :closePopup="() => closePopup()" v-else> </payment>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { showSnackBar } from '@/utilities';

export default {
  data() {
    return {
      width: "50%",
      margin: "10% auto",
      radiusType: "full",
      paymentDetail: null,
      localDisplayLang: null,
      planid: null, // "ZCOHHJI6" "41XETGXH" "Q7FHQ4LM"
      couponCode: null,
      planSelected: null,
      gatewayResponse: [],
    };
  },
  created() {
    eventBus.$on("close-plan-popup", (data) => {
      if (data) {
        this.closePopup();
      }
    });

    this.setPlanDeeplinkPath(this.$router.currentRoute.fullPath);
		sessionStorage.setItem("setPlanDeeplinkPath", this.$router.currentRoute.fullPath)

    this.planFunc();
    sessionStorage.removeItem("voucherCode");
  },
		mounted() {
		this.currentRoute = this.$route.params;
		if(this.currentRoute.planid) {
			this.listGateways();
		}
	},

  watch: {
    subscriberid(val) {
      if (val) {
        this.planFunc();
      }
    },
  },

  computed: {
    ...mapGetters(["subscriberid", "profileid"]),
  },

  methods: {

    ...mapMutations(["setPlanDeeplinkPath"]),
		...mapActions(["getPlan"]),

    closePopup() {
      this.setPlanDeeplinkPath(null);
			sessionStorage.removeItem("setPlanDeeplinkPath")
      sessionStorage.removeItem("voucherCode");
			localStorage.removeItem("planId");	
				this.$router.push({ name: "Home"});
				eventBus.$emit("rerender-menus");
    },

    planFunc() {
      // if (this.subscriberid && this.profileid && this.profileid === this.subscriberid) {
      // this.$nextTick(() => {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
        this.planid = this.$route.params.planId;
        this.couponCode = this.$route.params.coupon;

        localStorage.setItem("offerCoupon", JSON.stringify({ planId: this.planid, coupon: this.couponCode}));
				localStorage.setItem("planId", this.planid);

        if (!this.subscriberid) {
          //open lookup popup.
          // let payload = {
          //   state: true,
          //   formType: "lookup",
          // };
          // eventBus.$emit("authPopup", payload);

          this.$router.push("/viewAllPlans");

          return;
        }

        if (this.subscriberid && this.profileid && this.profileid !== this.subscriberid) {
          // snackbar.show({
          //   text: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
          //   textColor: "#ffffff",
          //   pos: "bottom-center",
          //   actionTextColor: "#de2600",
          // });
          showSnackBar(this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"));
          this.closePopup();
        }

        if (this.planid) {
          //event for fetching available plans for given availability id.
          eventBus.$on("planlist-subscriptions-response", (response) => {
            console.log("planlist response", response);
            if (!response.reason) {
              this.planSelected = response.data.find((element) => {
                return element.planid === this.planid;
              });

              if (this.planSelected) {
                this.listGateways();
			}else {
			let payload = {
				planid: this.planid,
			};
	this.getPlan(payload)
		.then((response) => {
			if (!response.reason) {
				let plan = response.data;
				this.planSelected = response.data;
				this.listGateways();
				console.log("response of getplan api", plan);
			}
		})
		.catch((error) => console.log("planError", error));
	}
		} else {
			//alert("THIS IS New 401 TEST")
              console.log("THIS IS New 401 TEST", response);
              if (response.errorcode === 6066) {
                eventBus.$on("re-login-success", (data) => {
                  if (data) {
                    this.planFunc();
                  }
                });

                return;
              }

              //error handling
              // snackbar.show({
              //   text: this.$t(response.reason),
              //   textColor: "#ffffff",
              //   pos: "bottom-center",
              //   actionTextColor: "#de2600",
              // });

              showSnackBar(this.$t(response.reason));

              this.closePopup();
            }
          });

          eventBus.$on("listgateway-response", (response) => {
            if (!response.data.reason) {
              this.gatewayResponse = response.data;
              this.constructPaymentDetail();
            } else {
              //error handling
              // snackbar.show({
              //   text: this.$t(response.data.reason),
              //   textColor: "#ffffff",
              //   pos: "bottom-center",
              //   actionTextColor: "#de2600",
              // });

              showSnackBar(this.$t(response.data.reason));

              this.closePopup();
            }
          });
				setTimeout(() => {
					this.listPlans();
				},3000)			 
			 }
      // });
      // }

      // else {
      //   snackbar.show({
      //     text: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
      //     textColor: "#ffffff",
      //     pos: "bottom-center",
      //     actionTextColor: "#de2600",
      //   });

      //   this.closePopup();
      // }
    },

    toggleContentDetail(state) {},

    listPlans() {
      let payload = {
        planvisibility: "YES",
        // descriptiontest: "STC",
        displaylanguage: this.localDisplayLang,
      };
      eventBus.$emit("planList_subscriptions", payload);
    },
    listGateways() {
      eventBus.$emit("listGateway");
    },
    constructPaymentDetail() {
      let payload = {
        state: true,
        detail: this.planSelected,
        gateways: this.gatewayResponse,
        transactionPurpose: "SUBSCRIPTION",
      };

      this.paymentDetail = payload;
    },
  },
  components: {
    payment: () => import(/* webpackChunkName: "payment" */ "@/components/Popups/Payment/Payment.vue"),
    overlay: () => import(/* webpackChunkName: "overlayLight" */ "@/components/Templates/overlayLoader.vue"),
  },
  beforeDestroy() {
    eventBus.$off("planlist-subscriptions-response");
    eventBus.$off("listgateway-response");
    eventBus.$off("close-plan-popup");
    eventBus.$off("re-login-success");
  },
};
</script>

<style></style>
